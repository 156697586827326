import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TablePagination } from "@mui/material";
import { ApiService, CommentService } from "../../../../../api";
import { CommentBox } from "./components";
import { SegFlags, isSegFeatureEnabled, translate } from "../../../../../common/providers";
import styles from "./CommentsRightPanel.module.css";
import {
	CircularLoader,
	CommonFilters,
	DocumentFilter,
	FiltersWrapper,
	IconComponent,
	ViewTabs,
	icon,
} from "../../../../../common/components";
import { setCommentsRowsPerPage } from "../../../../../common/slice";
import { setProjectCommentFilters } from "../../../slice/project-document/project-document-slice";
import { isDeepEqual, isNonEmptyArray } from "../../../../../common/utils";
import { COMMENT_FILTER_OPTIONS } from "../../../constants/constants";

const rowsPerPageOptions = [25, 50, 100];

export default function CommentsRightPanel({ isValidationComments = false }) {
	const cancelTokenSourceRef = useRef(null);
	const scrollRef = useRef(null);
	const redirectScroll = useSelector(({ srDocument }) => srDocument.sidePanelCommentScroll);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const projectCommentFilters = useSelector(({ srProjectDocument }) => srProjectDocument.projectCommentFilters);
	const [commentPage, setCommentPage] = useState(0);
	const [isLoadingComments, setIsLoadingComments] = useState(false);
	const [comments, setComments] = useState(null);
	const [totalComments, setTotalComments] = useState(0);
	const [filters, setFilters] = useState(null);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const [selectedTab, setSelectedTab] = useState("open");
	const [counters, setCounters] = useState({});
	const projectId = useSelector(({ context }) => context.project?.id);
	const commentsRowsPerPage = useSelector(({ context }) => context.commentsRowsPerPage);
	const sidePanelOpen = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const sidePanelInformationId = useSelector(({ srDocument }) => srDocument.sidePanelInformationId);
	const dispatch = useDispatch();
	const tabHeaders = useMemo(
		() => [
			{
				id: "open",
				label: translate("common:comment.conversation.open", { count: counters?.open || 0 }),
			},
			{
				id: "closed",
				label: translate("common:comment.conversation.closed", { count: counters?.closed || 0 }),
			},
		],
		[counters]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (!isDeepEqual(projectCommentFilters, filters)) {
			setFilters(projectCommentFilters);
			setCommentPage(0);
		}
	}, [projectCommentFilters, filters]);
	const getComments = useCallback(() => {
		if (commentPage >= 0) {
			const payload = {
				documentIds: (filters?.documents?.elements?.length > 0 && filters?.documents) || null,
				search: filters?.content || null,
			};
			const iamTheWriter = filters?.projectComments?.includes(COMMENT_FILTER_OPTIONS.AUTHOR);
			const isReplied = filters?.projectComments?.includes(COMMENT_FILTER_OPTIONS.REPLIED);
			const iamMentionedIn = filters?.projectComments?.includes(COMMENT_FILTER_OPTIONS.MENTIONED);
			if (iamTheWriter) {
				payload.iamTheWriter = iamTheWriter;
			}
			if (isReplied) {
				payload.isReplied = isReplied;
			}
			if (iamMentionedIn) {
				payload.iamMentionedIn = iamMentionedIn;
			}
			const parameters = {
				page: commentPage,
				limit: commentsRowsPerPage,
			};
			setIsLoadingComments(true);
			const getCommentsRequest = (tab) =>
				isValidationComments
					? CommentService.getAllIssueComments(
							{ projectId },
							{ ...payload, isOpenForDiscussion: tab === "open" },
							parameters,
							cancelTokenSourceRef.current.token
					  )
					: CommentService.getAllPrimeProjectComments(
							{ projectId },
							{ ...payload, isOpenForDiscussion: tab === "open" },
							parameters,
							cancelTokenSourceRef.current.token
					  );
			getCommentsRequest(selectedTab)
				.then((data) => {
					setComments(data.contents);
					setTotalComments(data.totalElements);
					setCounters((prev) => ({ ...prev, [selectedTab]: data.totalElements }));
					const oppositeStatus = (selectedTab === "open" && "closed") || "open";
					getCommentsRequest(oppositeStatus)
						.then((oppositeData) => {
							setCounters((prev) => ({ ...prev, [oppositeStatus]: oppositeData.totalElements }));
						})
						.catch(console.error);
				})
				.catch(console.error)
				.finally(() => setIsLoadingComments(false));
		}
	}, [commentPage, commentsRowsPerPage, filters, isValidationComments, projectId, selectedTab]);
	useEffect(() => {
		if (!sidePanelInformationId) {
			getComments();
		}
	}, [sidePanelOpen, sidePanelInformationId, getComments, commentPage, commentsRowsPerPage, documentId]);
	useEffect(() => {
		if (scrollRef?.current && scrollRef.current.scrollTop <= 0 && redirectScroll > 0) {
			scrollRef.current.scrollTop = redirectScroll;
		}
	});
	const handlePageChange = (event, newPage) => {
		setCommentPage(newPage);
	};
	const handleRowsPerPageChange = (event) => {
		dispatch(setCommentsRowsPerPage(+event.target.value));
		setCommentPage(0);
	};
	const handleApply = (newFilters) => {
		dispatch(setProjectCommentFilters(newFilters));
	};
	const handleSetSelectedDocuments = (newSelections) => {
		setSelectedDocuments(newSelections);
	};
	const handleChangeTab = (_, tab) => {
		setSelectedTab(tab);
		setCommentPage(0);
	};
	return (
		<div className={styles.container}>
			<ViewTabs
				className={styles.tabs}
				selectedTab={selectedTab}
				tabIdentifier="id"
				tabs={tabHeaders}
				variant="scrollable"
				onChange={handleChangeTab}
			/>
			<FiltersWrapper
				hasSearch
				multiline
				className={styles.filters__container}
				components={[
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
						component: CommonFilters.DOCUMENTS,
						resizable: true,
						renderer: (
							<DocumentFilter
								selectedDocuments={selectedDocuments}
								onSetSelectedDocuments={handleSetSelectedDocuments}
							/>
						),
						withDescription: false,
						disablePortal: false,
					},
					{
						default: true,
						enabled: true,
						multiSelection: true,
						labelKey: "label",
						valueKey: "key",
						component: CommonFilters.PROJECT_COMMENTS,
					},
				]}
				defaultFilters={filters}
				filterPopUpPlacement="bottom-end"
				searchClassName={styles.filters__search}
				searchLabel={translate("smart-review.right-panel.comments.search-comments-user")}
				searchPlaceHolder=""
				onApply={handleApply}
			/>
			<div ref={scrollRef} className={styles.comment__container}>
				{(!isLoadingComments &&
					((isNonEmptyArray(comments) &&
						comments.map((comment) => (
							<CommentBox
								key={comment.commentId}
								comment={comment}
								commentPage={commentPage}
								isOpenConversation={selectedTab === "open"}
								isValidationComments={isValidationComments}
								scrollRef={scrollRef}
							/>
						))) || (
						<div className={styles.emptyStateContainer}>
							<IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} />
							{translate("common:comment.empty-state.no-results")}
						</div>
					))) || (
					<div className={styles.loaderContainer}>
						<CircularLoader />
					</div>
				)}
			</div>
			<div className={styles.commentPagination}>
				<TablePagination
					component="div"
					count={totalComments}
					page={commentPage}
					rowsPerPage={commentsRowsPerPage}
					rowsPerPageOptions={rowsPerPageOptions}
					onPageChange={handlePageChange}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</div>
		</div>
	);
}
