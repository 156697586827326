import React, { useState, useRef, useEffect } from "react";
import { batch, useSelector, useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import {
	hasPermission,
	isSegFeatureEnabled,
	Permissions,
	SegFlags,
	translate,
	translateDate,
} from "../../../../../../common/providers";
import styles from "./Row.module.css";
import {
	Preview,
	CustomButton,
	CustomIconButton,
	VerticalDivider,
	icon,
	CategoryRowRiskLevel,
	IconComponent,
	CustomDialog,
	Hierarchy,
} from "../../../../../../common/components";
import { GonogoCategoryService, GonogoInformationService, ApiService } from "../../../../../../api";
import {
	updateTreeNode,
	deleteCategoryInformation,
	attachInformationCategory,
	updateFlag,
} from "../../../../slice/gonogo-slice";
import { SENTENCE_TYPE } from "../../../../../my-review/constants/constants";
import CommentsSidePanel from "../comments-side-panel/CommentsSidePanel";
import { dangerousSetInnerHTML } from "../../../../../../common/utils";

const TEXT_LIMIT = 600;

export default function Row({ data, onRefresh, rowIndex }) {
	const [flagged, setFlagged] = useState(false);
	const [openReadMore, setOpenReadMore] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openPreview, setOpenPreview] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [selectedInformation, setSelectedInformation] = useState(null);
	const selectedMode = useSelector(({ gonogo }) => gonogo.selectedMode);
	const readMoreContent = (data?.content || "").length > TEXT_LIMIT;
	const dispatch = useDispatch();
	const currentCategory = useSelector(({ gonogo }) => gonogo.currentCategory);
	const projectId = useSelector(({ context }) => context.project.id);
	const cancelTokenSourceRef = useRef(null);
	const updateCancelTokenSourceRef = useRef(null);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		updateCancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
			ApiService.cancelTokens(updateCancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setFlagged(data.attached);
	}, [data]);
	const handleReadMore = () => {
		setOpenReadMore((prev) => !prev);
		if (typeof onRefresh === "function") {
			onRefresh(rowIndex);
		}
	};
	const handleClickFlag = () => {
		batch(() => {
			dispatch(
				updateTreeNode({
					id: currentCategory.id,
					updates: { attachedContentsCount: currentCategory.attachedContentsCount + (flagged ? -1 : 1) },
				})
			);
			dispatch(
				attachInformationCategory({
					categoryId: currentCategory.id,
					informationId: data.informationId,
					payload: !flagged,
					token: cancelTokenSourceRef.current.token,
				})
			);
		});

		dispatch(
			updateFlag({
				informationId: data.informationId,
				flag: !flagged,
			})
		);

		setFlagged((prev) => !prev);
	};
	const handleUpdateTree = () => {
		GonogoCategoryService.getCategory(
			{ projectId, categoryId: currentCategory.id },
			updateCancelTokenSourceRef.current.token
		)
			.then((cats) => {
				batch(() => {
					dispatch(updateTreeNode(cats));
					dispatch(
						deleteCategoryInformation({
							categoryId: currentCategory.id,
							informationId: data.informationId,
						})
					);
				});
			})
			.catch((error) => console.error(error));
	};
	const textRenderer = () =>
		((openReadMore || !readMoreContent) && dangerousSetInnerHTML({ text: data?.content })) ||
		(!openReadMore && readMoreContent && (
			<>{dangerousSetInnerHTML({ text: data?.content.slice(0, TEXT_LIMIT) })}...</>
		));
	const handleDelete = () => {
		GonogoInformationService.deleteCategoryInformation(
			{ categoryId: currentCategory.id, informationId: data.informationId },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				handleUpdateTree();
				onRefresh(rowIndex);
			})
			.catch((err) => console.error(err));
	};
	const handleRevert = () => {
		GonogoInformationService.revertCategoryInformation(
			{ categoryId: currentCategory.id, informationId: data.informationId },
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				handleUpdateTree();
			})
			.catch((err) => console.error(err));
	};
	const handlePreview = () => {
		setOpenPreview((prev) => !prev);
	};
	const handleClickDelete = () => {
		if (flagged) {
			setOpenDeleteDialog(true);
		} else if (openDelete) {
			handleDelete();
		} else {
			setOpenDelete((prev) => !prev);
		}
	};
	const handleLeaveRow = () => {
		if (openDelete) {
			setOpenDelete(false);
		}
		setHovered(false);
	};
	const handleEnterRow = () => {
		setHovered(true);
	};
	const handleClose = () => {
		setOpenDeleteDialog(false);
	};
	const handleOpenComments = () => {
		setSelectedInformation(data);
	};
	const handleCloseComments = () => {
		setSelectedInformation(null);
	};
	return (
		<div
			className={styles.row}
			data-cy="content.row.detail"
			onMouseEnter={handleEnterRow}
			onMouseLeave={handleLeaveRow}
		>
			<div className={styles.header} data-testid={`contentRowDetail_${data.informationId}`}>
				<div className={styles.header__info} data-hovered={hovered || data.commentsCount > 0}>
					<span className={styles["header--rowId"]}>
						{data?.informationType === SENTENCE_TYPE.INFORMATION ||
						!isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) ? (
							<>
								<IconComponent
									className={styles.infoIcon}
									color="var(--color-light-grey-1)"
									icon={icon.faStream}
								/>
								{`INFO_${data?.informationId}`}
							</>
						) : (
							<>
								<IconComponent
									className={styles.requiredIcon}
									color="var(--color-red)"
									icon={icon.faAsterisk}
								/>
								{`REQ_${data?.informationId}`}
							</>
						)}
					</span>
					<VerticalDivider className={styles.verticalDivider} />
					<Hierarchy
						enableFirstLocationCut
						charsKeptInEnd={8}
						className={styles.breadcrumbContainer}
						documentName={data?.documentName}
						hierarchy={data?.fullToc}
					/>
				</div>
				{(selectedMode !== 2 && (
					<>
						<div className={styles.header__actions}>
							{(hovered || data.commentsCount > 0) && (
								<>
									<div className={styles.header__mainActions}>
										<CustomIconButton
											data-testid="display.icon.btn"
											icon={icon.faFile}
											size="sm"
											tooltip={translate("gonogo.content.row.display")}
											variant="outlined"
											onClick={handlePreview}
										/>
										<VerticalDivider className={styles.verticalDivider} />
										{hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) && (
											<>
												{(!openDelete && (
													<CustomIconButton
														data-testid="trash.icon.btn"
														icon={icon.faTrash}
														size="sm"
														tooltip={translate("gonogo.content.row.delete")}
														variant="outlined"
														onClick={handleClickDelete}
													/>
												)) || (
													<CustomButton
														className={styles.confirmDelete}
														data-testid="confirm.delete.btn"
														startIcon={icon.faTrash}
														variant="outlined"
														onClick={handleClickDelete}
													>
														{translate("common:btn.confirm")}
													</CustomButton>
												)}
											</>
										)}
									</div>
									<div
										className={styles.commentBtn__container}
										data-has-comments={data.commentsCount > 0}
									>
										<CustomIconButton
											color={data.commentsCount > 0 ? "primary" : "secondary"}
											data-testid="comment.icon.btn"
											icon={icon.faComments}
											size="sm"
											tooltip={translate("gonogo.comments-side-panel.comments")}
											variant="outlined"
											onClick={handleOpenComments}
										/>
									</div>
								</>
							)}
							{hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) && (
								<div className={styles.flaggedBtn__container} data-testid="flag.icon.container">
									{(flagged || hovered || data.commentsCount > 0) && (
										<CustomIconButton
											btnClassName={(flagged && styles["flagged--button"]) || ""}
											data-testid="flag.icon.btn"
											icon={icon.faFlag}
											iconClassName={(flagged && styles["flagged--icon"]) || ""}
											size="sm"
											tooltip={
												(flagged && translate("gonogo.content.row.remove-from-important")) ||
												translate("gonogo.content.row.add-to-important")
											}
											variant="outlined"
											onClick={handleClickFlag}
										/>
									)}
								</div>
							)}
						</div>
					</>
				)) || (
					<div className={styles.header__mainActions}>
						<span className={styles.deletedAt}>
							{translate("gonogo.content.row.deleted-on")}&nbsp;
							{translateDate(data.date).replaceAll(".", "/")}
						</span>
						{hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]) && (
							<>
								<VerticalDivider className={styles.verticalDivider} />
								<CustomIconButton
									icon={icon.faTrashArrowUp}
									size="sm"
									tooltip={translate("gonogo.content.row.remove-from-deleted")}
									variant="outlined"
									onClick={handleRevert}
								/>
							</>
						)}
					</div>
				)}
			</div>
			{Array.isArray(data.categories) && data.categories.length > 0 && (
				<CategoryRowRiskLevel categories={data.categories} containerId={data?.informationId} />
			)}
			<div className={styles.content}>
				{textRenderer()}
				{readMoreContent &&
					((openReadMore && (
						<span className={styles.readMore} role="presentation" onClick={handleReadMore}>
							{translate("gonogo.content.row.read-less")}
						</span>
					)) || (
						<span className={styles.readMore} role="presentation" onClick={handleReadMore}>
							{translate("gonogo.content.row.read-more")}
						</span>
					))}
			</div>
			<Divider />
			<Preview
				coordinates={data?.positions}
				docId={data?.documentId}
				infoId={data?.informationId}
				open={openPreview}
				page={data?.page}
				projectId={projectId}
				onClose={handlePreview}
			/>
			<CustomDialog
				data-testid="confirm.delete.dialog"
				iconColor="var(--color-red)"
				iconModel={icon.faExclamationTriangle}
				open={openDeleteDialog}
				submitLabel={translate("gonogo.confirm-delete.button")}
				subTitle={translate("gonogo.details.important-content.delete-popup.helperText", {
					selectedCategory: currentCategory.name,
				})}
				title={translate("gonogo.details.important-content")}
				onClose={handleClose}
				onSubmit={() => {
					handleDelete();
					handleClose();
				}}
			/>
			<CommentsSidePanel
				information={selectedInformation}
				open={!!selectedInformation?.informationId}
				onClose={handleCloseComments}
			/>
		</div>
	);
}
