import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CommonFilters, DocumentFilter, FiltersWrapper, ListWithRequest } from "../../../../common/components";
import { SegFlags, isSegFeatureEnabled } from "../../../../common/providers";
import { ApiService, ProjectTeamService, QATagService } from "../../../../api";
import { isNonEmptyArray, isNonEmptyObject } from "../../../../common/utils";

const generatePayload = (filters) => {
	if (!isNonEmptyObject(filters)) {
		return {};
	}
	const { documents, users, ...rest } = filters;
	const newFilters = {};
	if (isNonEmptyArray(documents?.elements)) {
		newFilters.documentIds = documents;
	}
	if (isNonEmptyArray(users)) {
		newFilters.userIds = users;
	}
	return { ...newFilters, ...rest };
};
export default function QASearch({ onClickSearch }) {
	const projectId = useSelector(({ context }) => context.project.id);
	const [filters, setFilters] = useState(null);
	const [usersRequest, setUsersRequest] = useState(null);
	const [tagsRequest, setTagsRequest] = useState(null);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const cancelTokenSourceRef = useRef(null);

	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		setUsersRequest(
			() =>
				({ search }) =>
					ProjectTeamService.searchUsersInProjectAndCompany(
						{ projectId },
						search || "",
						cancelTokenSourceRef.current.token
					)
		);
	}, [projectId]);
	useEffect(() => {
		setTagsRequest(
			() =>
				({ search }) =>
					QATagService.searchQuesionTagInProject(
						{ projectId },
						search || "",
						cancelTokenSourceRef.current.token
					)
		);
	}, [projectId]);
	useEffect(() => {
		onClickSearch(generatePayload(filters));
	}, [onClickSearch, filters]);
	const handleSetSelectedDocuments = (newSelections) => {
		setSelectedDocuments(newSelections);
	};
	const handleApply = (newFilters) => {
		setFilters(newFilters);
	};
	return (
		<FiltersWrapper
			hasSearch
			components={[
				{
					default: true,
					enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
					component: CommonFilters.DOCUMENTS,
					resizable: true,
					renderer: (
						<DocumentFilter
							selectedDocuments={selectedDocuments}
							onSetSelectedDocuments={handleSetSelectedDocuments}
						/>
					),
					withDescription: false,
				},
				{
					default: true,
					enabled: true,
					renderer: <ListWithRequest labelName="displayName" request={usersRequest} />,
					component: CommonFilters.USERS,
				},
				{
					default: true,
					enabled: true,
					renderer: <ListWithRequest labelName="tag" request={tagsRequest} />,
					component: CommonFilters.TAGS,
				},
			]}
			defaultFilters={filters}
			onApply={handleApply}
		/>
	);
}
