import { Checkbox } from "@mui/material";
import React from "react";
import { icon, CustomIconButton, TextMidCut } from "../../../../..";
import styles from "./Item.module.css";

export default function Item({
	folder = false,
	iconName = null,
	label = "",
	next = false,
	onClickItem,
	selected = false,
	hasContent = true,
	disabled,
}) {
	return (
		<div
			className={`${styles.item}  ${!folder && !next && styles["itemIcon--padding"]} ${
				next && !hasContent && styles["item__no-content"]
			}`}
			role="presentation"
			onClick={onClickItem}
		>
			{(iconName === "faFilePdf" && (
				<Checkbox
					checked={selected}
					classes={{
						root: styles.checkboxRoot,
						icon: styles.checkboxIcon,
					}}
					color="primary"
					disabled={disabled}
				/>
			)) ||
				(iconName && (
					<CustomIconButton
						className={styles.identifierButton}
						icon={icon[iconName]}
						iconClassName={`${styles.itemIcon}`}
						size="sm"
					/>
				))}
			<TextMidCut showWhenOverflow tooltip className={styles.itemText} text={label} />
			{next && hasContent && (
				<CustomIconButton
					className={styles.expandButton}
					icon={icon.faChevronRight}
					iconClassName={styles.itemIconNext}
					size="sm"
				/>
			)}
		</div>
	);
}
