import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService, MyService, UserService } from "../../api";
import KeycloakService from "../../services/KeycloakService";
import { aitendersLogo } from "../../common/resources/images";
import { CircularLoader, CustomButton, CustomIconButton, icon } from "../../common/components";
import styles from "./Login.module.css";
import { AnalyticsProvider, ContextProvider, translate } from "../../common/providers";
import { setCompany, setToken, setHasEntered, setloggedPhysicalComp } from "../../common/slice/contextSlice";
import permissions from "../../common/providers/permissions/permissions";
import { NavUtils } from "../../navigation";
import { isNonEmptyArray } from "../../common/utils";

const hasProperClaims = () =>
	KeycloakService.hasRole([permissions.REALM.AITENDERS.claim]) ||
	KeycloakService.hasRole([permissions.REALM.ADMIN.claim]);

export default function Login() {
	const loginCompanies = useSelector(({ context }) => context.companies);
	const loggedPhysicalCompId = useSelector(({ context }) => context.loggedPhysicalComp);
	const cancelTokenSourceRef = useRef(null);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const reset = useCallback(() => {
		setSelectedCompany(null);
	}, []);
	useEffect(() => {
		const documentTitle = "Sign in | Aitenders";
		document.title = documentTitle;
		AnalyticsProvider.trackPageView({ documentTitle: "Sign in" });
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => reset();
	}, [reset]);
	const accessToPlatform = (company) => {
		if (!company) {
			return;
		}
		UserService.loginWithCompany({ companyId: company.id }, cancelTokenSourceRef.current.token)
			.then(() => {
				dispatch(setHasEntered());
				dispatch(setCompany(company));
				dispatch(
					setloggedPhysicalComp(loginCompanies.find((c) => c.id === company?.id)?.id || loggedPhysicalCompId)
				);
				dispatch(setToken(KeycloakService.getToken()));
				if (ContextProvider.getHomeTab() === "opportunity") {
					navigate(NavUtils.goToOpportunity());
				}
			})
			.catch((err) => console.error(err));
	};
	useEffect(
		() => {
			if (loginCompanies) {
				if (loginCompanies.length < 1) {
					return;
				}
				if (loginCompanies.length >= 1) {
					const goingHome = location.pathname === "/";
					if (goingHome && loginCompanies.length > 1) {
						return;
					}
					if (!goingHome) {
						MyService.getDetails(cancelTokenSourceRef.current.token)
							.then((data) => {
								if (!data) {
									return;
								}
								const { companyId: id, companyName: name, roles, companyType } = data;
								accessToPlatform({ id, name, roles, companyType });
							})
							.catch(console.error);
					} else if (loginCompanies.length === 1) {
						accessToPlatform(loginCompanies[0]);
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, loginCompanies, navigate]
	);
	useEffect(() => {
		if (isNonEmptyArray(loginCompanies) && loginCompanies.length > 1) {
			const urlCompanyId = Number.parseInt(new URLSearchParams(location.search).get("companyId"), 10);
			if (urlCompanyId) {
				accessToPlatform(loginCompanies.find((company) => company.id === urlCompanyId));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, loginCompanies]);
	const handleLogOut = () => {
		const cancelToken = ApiService.getCancelTokenSource().token;
		UserService.logout(cancelToken).finally(() => {
			if (hasProperClaims()) {
				KeycloakService.doLogout().then(() => {
					KeycloakService.clearToken();
				});
			}
		});
		reset();
	};
	useEffect(
		() => {
			if (KeycloakService.isLoggedIn() && (!hasProperClaims() || loginCompanies?.length === 0)) {
				KeycloakService.doLogout().then(() => {
					KeycloakService.clearToken();
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[loginCompanies]
	);

	const selectCompanyToBeLoggedWith = (event) => {
		event.preventDefault();
		if (!selectedCompany) {
			return;
		}
		accessToPlatform(selectedCompany);
	};

	const handleSelectCompany = (e) => setSelectedCompany(e.target.value);

	return (
		<>
			{hasProperClaims() && (
				<div className={styles["main-container"]}>
					{(Array.isArray(loginCompanies) && loginCompanies.length > 1 && (
						<>
							<img alt="aitenders_logo" src={aitendersLogo} />
							<form id="accessForm" onSubmit={selectCompanyToBeLoggedWith}>
								<FormControl fullWidth required margin="normal">
									<InputLabel>{translate("common:login.input.label.companies-availabe")}</InputLabel>
									<Select
										data-testid="login.selectCompany.input"
										label={translate("common:login.input.label.companies-availabe")}
										MenuProps={{
											"data-testid": "login.selectCompany.menu",
										}}
										value={selectedCompany || ""}
										onChange={handleSelectCompany}
									>
										{(loginCompanies || []).map((cny) => (
											<MenuItem key={cny.id} value={cny}>
												<strong style={{ width: "100%" }} translate="no">
													{cny.companyName}
												</strong>
												&nbsp;
												<span
													style={{
														color: "var(--color-light-grey-1)",
														fontSize: "var(--font-size-xs)",
													}}
												>
													{translate("common:login.menu-item.role")}
													{(cny.role && cny.role.replace("_", " ")) || "None"}
												</span>
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<div className={styles["multi-companies__btns"]}>
									<CustomIconButton
										color="secondary"
										data-testid="login.backBtn"
										icon={icon.faArrowLeft}
										id="goBackBtn"
										variant="contained"
										onClick={handleLogOut}
									/>
									&emsp;
									<CustomButton
										fullWidth
										color="primary"
										data-testid="login.selectCompany.btn"
										disabled={!selectedCompany}
										id="accessBtn"
										size="lg"
										type="submit"
										variant="contained"
									>
										{translate("common:btn.access-platform")}
									</CustomButton>
								</div>
							</form>
						</>
					)) || <CircularLoader size={80} />}
				</div>
			)}
		</>
	);
}
