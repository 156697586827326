import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { InputAdornment, MenuItem } from "@mui/material";
import { IconComponent, CustomButton, icon, InfiniteListSelector, SearchInput } from "../../../../../common/components";
import {
	deleteSearch,
	getAllSearches,
	search,
	setCurrentSearch,
	editSearch,
	setIsLoadingSearch,
	updateSearchResultCount,
	setItems,
	setIsUpdating,
} from "../../slice/my-search-slice";
import styles from "./SmartSearchDrawer.module.css";
import { SmartSearchMenu } from "../../../components";
import { ApiService, ProjectTeamService, SearchCardService } from "../../../../../api";
import { Flags, isFeatureEnabled, translate } from "../../../../../common/providers";
import { useApi } from "../../../../../common/hooks";
import AddTemplateSidePanel from "./add-template-sidepanel/AddTemplateSidePanel";
import { OPERATORS, VISIBILITY } from "../../../../../common/constants";
import { debounce } from "../../../../../common/utils";

const SEARCH_TYPES = {
	MY_SEARCHES: "mySearches",
	PROJECT_SEARCHES: "projectSearches",
};
const SEARCH_VISIBILITY = {
	PRIVATE: "PRIVATE",
	PUBLIC: "PUBLIC",
};
const debouncedFunction = debounce((func) => func());
const generateFilters = ({ menus, openedMenuIndex, authorInput, searchValueDebounced }) => {
	const filters = { visibility: menus[openedMenuIndex].visibility };
	if (authorInput) {
		filters.users = [authorInput.id];
	}
	if (searchValueDebounced) {
		filters.name = searchValueDebounced;
	}
	return filters;
};
export default function SmartSearchDrawer({ isForMySearch }) {
	const companyId = useSelector(({ context }) => context.company.id);
	const currentSearchId = useSelector(({ mySearch }) => mySearch.currentSearch?.id || 0);
	const mySearches = useSelector(({ mySearch }) => mySearch.mySearches);
	const projectId = useSelector(({ context }) => context.project.id);
	const projectSearches = useSelector(({ mySearch }) => mySearch.projectSearches);
	const searchLimit = useSelector(({ mySearch }) => mySearch.limit);
	const isUpdating = useSelector(({ mySearch }) => mySearch.isUpdating);

	const [authorsRequest, setAuthorsRequest] = useState(null);
	const [authorInput, setAuthorInput] = useState(null);
	const [infiniteListRequest, setInfiniteListRequest] = useState(null);
	const [isSearchesLoading, setIsSearchesLoading] = useState(false);
	const [openAddTemplatePanel, setOpenAddTemplatePanel] = useState(false);
	const [openedMenuIndex, setOpenedMenuIndex] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	const [searchValueDebounced, setSearchValueDebounced] = useState("");
	const [requestUpdating, setRequestUpdating] = useState(false);

	const tokenSrcRef = useRef(null);
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { call: getSearchCard, cancel: cancelGetSearchCard } = useApi(SearchCardService.get);

	const menus = useMemo(
		() => [
			{
				key: SEARCH_TYPES.MY_SEARCHES,
				visibility: SEARCH_VISIBILITY.PRIVATE,
				title: translate("smart-search.drawer.menu.my-searches"),
				items: mySearches,
			},
			{
				key: SEARCH_TYPES.PROJECT_SEARCHES,
				visibility: SEARCH_VISIBILITY.PUBLIC,
				title: translate("smart-search.drawer.menu.project-searches"),
				items: projectSearches,
			},
		],
		[mySearches, projectSearches]
	);

	useEffect(() => {
		tokenSrcRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSrcRef.current);
		};
	}, []);

	useEffect(() => {
		setAuthorsRequest(
			() =>
				({ limit: authorsLimit, page }) =>
					ProjectTeamService.getUsersByCompany(
						{
							projectId,
							companyId,
						},
						{ limit: authorsLimit, page },
						tokenSrcRef.current.token
					)
		);
	}, [projectId, companyId]);

	useEffect(
		() => {
			if ((projectId || isUpdating) && openedMenuIndex >= 0 && menus[openedMenuIndex]) {
				setRequestUpdating(true);
				setInfiniteListRequest(
					() =>
						({ page, limit }) =>
							SearchCardService.getAll(
								{ projectId },
								{
									page,
									limit,
									filters: generateFilters({
										openedMenuIndex,
										menus,
										authorInput,
										searchValueDebounced,
									}),
								},
								tokenSrcRef.current.token
							).then((data) => {
								dispatch(setItems({ items: data.contents || [], key: menus[openedMenuIndex].key }));
								return data;
							})
				);
				setTimeout(() => {
					setRequestUpdating(false);
				}, 50);
				if (isUpdating) {
					dispatch(setIsUpdating(false));
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, isUpdating, openedMenuIndex, authorInput, searchValueDebounced]
	);

	const handleChangeAuthorInput = (newAuthor) => {
		setAuthorInput(newAuthor);
	};
	const handleNewSearch = () => {
		ApiService.cancelTokens(tokenSrcRef.current);
		tokenSrcRef.current = ApiService.getCancelTokenSource();
		batch(() => {
			dispatch(
				setCurrentSearch({
					currentSearch: {
						isNew: true,
						id: 0,
						creatorId: 0,
						title: "",
						description: "",
						visibility: VISIBILITY.PRIVATE,
						listKey: "",
					},
					filters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
					isFilterTocOut: false,
					totalMatches: 0,
					page: 0,
				})
			);
			dispatch(setIsLoadingSearch(true));
			dispatch(search({ page: 0, limit: searchLimit, token: tokenSrcRef.current.token }));
		});
	};
	const handleClickSearch = (key, { id, ...rest }) => {
		const page = 0;
		cancelGetSearchCard();
		batch(() => {
			dispatch(setIsLoadingSearch(true));
			dispatch(
				setCurrentSearch({
					currentSearch: {
						isNew: false,
						id,
						listKey: key,
						...rest,
					},
					filters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
					totalMatches: 0,
					page: 0,
					totalPages: 0,
					hasMore: false,
					results: [],
				})
			);
		});
		getSearchCard({ searchCardId: id }, page, searchLimit)
			.then(({ details, filters: filterCount }) => {
				const { contents, totalPages, hasMore } = details;
				const { filters, resultCount } = filterCount;
				const { isFilterTocOut, ...actualFilters } = filters;
				batch(() => {
					dispatch(setIsLoadingSearch(false));
					dispatch(
						updateSearchResultCount({
							id,
							resultCount,
							listKey: key,
						})
					);
					dispatch(
						setCurrentSearch({
							currentSearch: {
								isNew: false,
								id,
								listKey: key,
								...rest,
							},
							filters: actualFilters,
							isFilterTocOut,
							totalMatches: resultCount,
							page,
							totalPages,
							hasMore,
							results: contents,
						})
					);
				});
			})
			.catch((err) => {
				console.error(err);
				dispatch(setIsLoadingSearch(false));
			});
	};

	useEffect(() => {
		const newSearchCardId = +searchParams.get("searchCardId") || 0;
		if (newSearchCardId > 0) {
			let searchCard = null;
			const key = menus.find((menu) =>
				menu.items.some((item) => {
					const isEqual = item.id === newSearchCardId;
					if (isEqual) {
						searchCard = item;
					}
					return isEqual;
				})
			)?.key;
			if (searchCard && key) {
				handleClickSearch(key, searchCard);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, menus]);

	const handleEditSearch = ({ id, ...payload }) => {
		dispatch(
			editSearch({
				searchCardId: id,
				creatorId: payload.creatorId,
				payload: { ...payload, description: payload.description || undefined },
				token: tokenSrcRef.current.token,
			})
		);
		dispatch(setIsUpdating(true));
	};
	const handleRemoveSearch = ({ id, creatorId }) => {
		dispatch(deleteSearch({ searchCardId: id, creatorId, token: tokenSrcRef.current.token })).then(() => {
			if (id === currentSearchId) {
				handleNewSearch();
			}
		});
		dispatch(setIsUpdating(true));
	};
	const handleOpenMenu = (index) => {
		setOpenedMenuIndex((prev) => (prev === index ? null : index));
	};
	const handleOpenAddTemplatePanel = () => {
		setOpenAddTemplatePanel(true);
	};
	const handleCloseAddTemplatePanel = () => {
		setOpenAddTemplatePanel(false);
		dispatch(getAllSearches({ token: tokenSrcRef.current.token, key: menus[openedMenuIndex].key }));
	};
	const handleChangeSearchText = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		setIsSearchesLoading(true);
		debouncedFunction(() => {
			setSearchValueDebounced(value);
			setIsSearchesLoading(false);
		});
	};
	const handleResetSearchText = () => {
		setSearchValue("");
		setSearchValueDebounced("");
	};

	const isMeSelected =
		Array.isArray(menus) && openedMenuIndex === menus.findIndex((menu) => menu.key === SEARCH_TYPES.MY_SEARCHES);
	const endAdornmentRenderer = (isOpened) => (
		<InputAdornment position="end">
			<IconComponent color="var(--color-dark-grey-1)" icon={isOpened ? icon.faCaretUp : icon.faCaretDown} />
		</InputAdornment>
	);
	const selectorRowRenderer = ({ loadedRow, onClick }) => (
		<MenuItem key={loadedRow?.id} onClick={() => onClick(loadedRow)}>
			<span>
				{loadedRow?.displayName ||
					loadedRow?.email ||
					translate("smart-search.add-template-panel.author-select.author")}
			</span>
		</MenuItem>
	);
	return (
		<>
			<div className={styles.drawer}>
				<div className={styles.buttonsContainer}>
					{isFeatureEnabled(Flags.SEARCHTEMPLATE) && (
						<CustomButton
							color="secondary"
							startIcon={icon.faPlus}
							variant="outlined"
							onClick={handleOpenAddTemplatePanel}
						>
							{translate("smart-search.drawer.add-template")}
						</CustomButton>
					)}
					<CustomButton
						className={styles.drawer__newSearchLabel}
						startIcon={icon.faSearch}
						variant="outlined"
						onClick={handleNewSearch}
					>
						{translate("smart-search.drawer.new-search")}
					</CustomButton>
				</div>
				<div className={styles.searchFieldsContainer}>
					<SearchInput
						fullWidth
						placeholder={translate("common:btn.search")}
						value={searchValue}
						onChange={handleChangeSearchText}
						onClearSearch={handleResetSearchText}
					/>
					<InfiniteListSelector
						nullable
						classes={{ input: styles.infiniteSelector }}
						classNameInfiniteList={styles.infiniteSelector__list}
						disabled={isMeSelected}
						endAdornmentRenderer={endAdornmentRenderer}
						infiniteListRequest={authorsRequest}
						rowRenderer={selectorRowRenderer}
						value={
							authorInput?.displayName ||
							translate("smart-search.add-template-panel.author-select.author")
						}
						onClick={handleChangeAuthorInput}
					/>
				</div>
				{menus.map((menu, index) => {
					const isMySearches = menu.key === SEARCH_TYPES.MY_SEARCHES;
					return (
						<SmartSearchMenu
							key={menu.key}
							hasAuthorsSearch
							currentSearchId={currentSearchId}
							infiniteRequest={infiniteListRequest}
							isForMySearch={isForMySearch}
							isLoading={isSearchesLoading}
							items={menu.items}
							menusCount={menus.length}
							open={openedMenuIndex === index && !requestUpdating}
							slice="mySearch"
							title={menu.title}
							onClickItem={(params) => handleClickSearch(menu.key, params)}
							onClickOpen={() => handleOpenMenu(index)}
							onEdit={(isMySearches && handleEditSearch) || null}
							onRemove={(isMySearches && handleRemoveSearch) || null}
						/>
					);
				})}
			</div>
			<AddTemplateSidePanel open={openAddTemplatePanel} onClose={handleCloseAddTemplatePanel} />
		</>
	);
}
