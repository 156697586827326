import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiService, OpportunityService } from "../../../../../api";
import { createNotification, CustomDialog, I18nTranslate, icon, IconComponent } from "../../../../../common/components";
import { usePromptNavigation } from "../../../../../common/hooks/usePrompt";
import { translate } from "../../../../../common/providers";
import { setPreventNavigation } from "../../../../../common/slice";
import {
	setIsClose,
	setIsUploading,
	setNeedUpdate,
	setOpenCancelDialogAll,
	setOpenUploadDialog,
	setUploadProgress,
} from "../../../slice/opportunitySlice";
import OpportunityProgress from "../opportunity-progress/OpportunityProgress";
import styles from "./OpportunityDrop.module.css";
import OpportunityCreationPanel from "../opportunity-creation-panel/OpportunityCreationPanel";
import { useApi } from "../../../../../common/hooks";
import { isAllowedFile, isZipMimeTypes } from "../../../../../common/utils";

const MAX_SIZE = 1610612736; // 1.5GB in bytes

const doctToCancelToken = new Map();
export default function OpportunityDrop() {
	const [openSidePanel, setOpenSidePanel] = useState(false);
	const [openMulti, setOpenMulti] = useState(false);
	const [openCancelDialogOne, setOpenCancelDialogOne] = useState(false);
	const [dragOver, setDragOver] = useState(false);
	const [isSingleProject, setIsSingleProject] = useState(true);
	const [cancelItem, setCancelItem] = useState("");
	const [pendingProjects, setPendingProjects] = useState([]);
	const [toSendDocuments, setToSendDocuments] = useState([]);
	const isLoadingList = useSelector(({ opportunity }) => opportunity.isLoadingList);
	const filteredIds = useSelector(({ opportunity }) => opportunity.filteredIds);
	const isUploading = useSelector(({ opportunity }) => opportunity.isUploading);
	const searchValue = useSelector(({ opportunity }) => opportunity.searchValue);
	const totalElements = useSelector(({ opportunity }) => opportunity.totalElements);
	const openCancelDialogAll = useSelector(({ opportunity }) => opportunity.openCancelDialogAll);
	const isClose = useSelector(({ opportunity }) => opportunity.isClose);
	const pendingDocumentsTokensSources = useRef([]);
	const inputBrowserRef = useRef(null);
	const tokenSourceRef = useRef(null);
	const dispatch = useDispatch();
	const { call: uploadNewOpportunity } = useApi(OpportunityService.uploadNewOpportunity);
	useEffect(() => {
		tokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(tokenSourceRef.current);
			pendingDocumentsTokensSources.current.forEach((s) => {
				ApiService.cancelTokens(s);
			});
		};
	}, []);
	usePromptNavigation({
		message: translate("opportunity.navigation.ongoing-upload.warning-message"),
		when: isUploading,
	});
	const displayNotification = (msg, notificationType = "error") => {
		createNotification({
			type: notificationType,
			message: msg,
			timeout: 3000,
		});
	};
	const uploadProject = (files, payload) => {
		dispatch(setOpenUploadDialog(true));
		const newPendingProjects =
			(payload.isSingleProject && [{ ...payload, files, progress: 0, single: true }]) ||
			files.map((f) => ({ document: f, progress: 0, single: false }));
		setPendingProjects((prev) => [...prev, ...newPendingProjects]);
		uploadNewOpportunity(
			files.filter((doc) => doc.size < MAX_SIZE),
			payload,
			(progressEvent) => {
				const { loaded, total } = progressEvent;
				const percentCompleted = Math.floor((loaded * 100) / total);
				dispatch(setUploadProgress(percentCompleted));
				dispatch(setIsUploading(true));
				dispatch(
					setPreventNavigation({
						active: true,
						message: translate("opportunity.navigation.ongoing-upload.warning-message"),
					})
				);
				setPendingProjects((prev) => {
					const proj =
						(payload.isSingleProject && prev.find((p) => p.name === payload.name)) ||
						(!payload.isSingleProject &&
							prev.filter((p) => files.some((f) => p?.document?.name === f.name)));
					if (proj) {
						const filteredPendingProjects = prev.filter(
							(p) =>
								(p.isSingleProject && p.name !== payload.name) ||
								(!p.isSingleProject && !files.some((f) => p?.document?.name === f.name))
						);
						if (Array.isArray(proj)) {
							proj.forEach((p) => {
								p.progress = percentCompleted;
							});

							filteredPendingProjects.push(...proj);
						} else {
							proj.progress = percentCompleted;
							filteredPendingProjects.push(proj);
						}
						return filteredPendingProjects;
					}
					return prev;
				});
			}
		)
			.then(() => {
				dispatch(setNeedUpdate(true));
				dispatch(setIsUploading(false));
				dispatch(setPreventNavigation({ active: false, message: "" }));
				displayNotification(translate("opportunity-import-success-message"), "success");
				setPendingProjects((prev) => {
					const proj =
						(payload.isSingleProject && prev.find((p) => p.name === payload.name)) ||
						(!payload.isSingleProject &&
							prev.filter((p) => files.some((f) => p?.document?.name === f.name)));
					if (proj) {
						const filteredPendingProjects = prev.filter(
							(p) =>
								(p.isSingleProject && p.name !== payload.name) ||
								(!p.isSingleProject && !files.some((f) => p?.document?.name === f.name))
						);
						if (Array.isArray(proj)) {
							proj.forEach((p) => {
								p.status = "ok";
							});
							filteredPendingProjects.push(...proj);
						} else {
							proj.status = "ok";
							filteredPendingProjects.push(proj);
						}
						return filteredPendingProjects;
					}
					return prev;
				});
				setIsSingleProject(true);
				setToSendDocuments([]);
			})
			.catch((err) => {
				dispatch(setIsUploading(false));
				dispatch(setPreventNavigation({ active: false, message: "" }));
				setPendingProjects((prev) => {
					const proj =
						(payload.isSingleProject && prev.find((p) => p.name === payload.name)) ||
						(!payload.isSingleProject &&
							prev.filter((p) => files.some((f) => p?.document?.name === f.name)));
					if (proj) {
						const filteredPendingProjects = prev.filter(
							(p) =>
								(p.isSingleProject && p.name !== payload.name) ||
								(!p.isSingleProject && !files.some((f) => p?.document?.name === f.name))
						);
						if (Array.isArray(proj)) {
							proj.forEach((p) => {
								p.status = p.progress === 100 ? "Error" : "Canceled";
							});
							filteredPendingProjects.push(...proj);
						} else {
							proj.status = proj.progress === 100 ? "Error" : "Canceled";
							filteredPendingProjects.push(proj);
						}
						return filteredPendingProjects;
					}
					return prev;
				});
				console.error(err);
			});
	};

	const handleMultiProject = (docsList) => {
		const payload = { isSingleProject: false, name: null, userIds: [] };
		uploadProject(docsList, payload);
	};
	const handleCloseConfirmationOne = () => {
		setOpenCancelDialogOne(false);
	};
	const handleCloseConfirmationAll = () => {
		dispatch(setOpenCancelDialogAll(false));
	};
	const handleCancelAllFilesUpload = () => {
		pendingDocumentsTokensSources.current.forEach((index) => {
			ApiService.cancelTokens(index);
		});
		dispatch(setOpenCancelDialogAll(false));
		pendingDocumentsTokensSources.current = [];
	};
	const handleCancelOneFileUpload = () => {
		let index = 0;
		if (pendingDocumentsTokensSources.current.length !== 1) {
			index = pendingProjects.findIndex((doc) => doc.document.name === cancelItem);
		}
		ApiService.cancelTokens(doctToCancelToken.get(cancelItem));
		setOpenCancelDialogOne(false);
		pendingDocumentsTokensSources.current.splice(index, 1);
		doctToCancelToken.delete(cancelItem);
		setCancelItem("");
	};
	const handleConfirmCancelOne = () => {
		handleCancelOneFileUpload();
	};
	const handleConfirmCancelAll = () => {
		handleCancelAllFilesUpload();
		if (isClose) {
			dispatch(setOpenUploadDialog(false));
			dispatch(setIsClose(false));
			const filteredRow = pendingProjects.filter((row) => row.status === "Error");
			setPendingProjects(filteredRow);
		}
	};
	const handleOpenConfirmationOne = (item) => {
		setOpenCancelDialogOne(true);
		setCancelItem(item);
	};
	const handleOpenConfirmationAll = () => {
		dispatch(setOpenCancelDialogAll(true));
	};
	const handleDragOver = (event) => {
		event.preventDefault();
		setDragOver(true);
	};
	const handleDragLeave = () => {
		setDragOver(false);
	};
	const handleHenerateInvalidUploadNotif = (files, allowedFiles) => {
		if (Object.values(files).length > allowedFiles.length) {
			createNotification({
				type: "error",
				message: translate("common:upload-file.bad-format"),
			});
		}
	};

	const handleDropUpload = (event) => {
		event.preventDefault();
		setDragOver(false);
		const allowedFiles = Object.values(event.dataTransfer.files).filter(
			(file) => typeof file !== "number" && isAllowedFile(file.type)
		);
		handleHenerateInvalidUploadNotif(event.dataTransfer.files, allowedFiles);
		if (allowedFiles.length > 0) {
			setToSendDocuments(allowedFiles);
			if (allowedFiles.length === 1 || !allowedFiles.some((af) => isZipMimeTypes(af.type))) {
				setOpenSidePanel(true);
			} else {
				setOpenMulti(true);
			}
		}
	};
	const handleBrowserUpload = (event) => {
		const newfiles = event.target.files;
		const allowedFiles = Object.values(newfiles).filter(
			(file) => typeof file !== "number" && isAllowedFile(file.type)
		);
		handleHenerateInvalidUploadNotif(newfiles, allowedFiles);
		if (allowedFiles.length > 0) {
			setToSendDocuments(allowedFiles);
			if (allowedFiles.length === 1 || !allowedFiles.some((af) => isZipMimeTypes(af.type))) {
				setOpenSidePanel(true);
			} else {
				setOpenMulti(true);
			}
		}
		inputBrowserRef.current.value = null;
	};
	const handleClosePanel = () => setOpenSidePanel(false);
	const hanldeCancelImport = () => {
		setIsSingleProject(false);
		setToSendDocuments([]);
		setOpenMulti(false);
		setIsSingleProject(true);
	};
	const handleSubmitImport = () => {
		setOpenMulti(false);
		if (isSingleProject) {
			setOpenSidePanel(true);
		} else {
			handleMultiProject(toSendDocuments);
		}
	};
	const emptyStateStyle = !isLoadingList && totalElements === 0 && searchValue === "" && filteredIds?.length === 0;
	return (
		<div
			className={emptyStateStyle ? styles.emptyState__Container : styles.import__Container}
			data-dragover={dragOver}
			onDragLeave={handleDragLeave}
			onDragOver={handleDragOver}
			onDrop={handleDropUpload}
		>
			<div className={styles.import__content}>
				<IconComponent
					className={emptyStateStyle ? styles.emptyState__import__icon : styles.import__icon}
					color="var(--tints-blue-30)"
					data-dragover={dragOver}
					icon={icon.faFileUpload}
				/>
			</div>
			<div className={styles.import__content}>
				<div className={styles.import__title}>{translate("opportunity.import.document")}</div>
				<div>
					<div className={emptyStateStyle ? styles.import__text : styles["import__text--inline"]}>
						<I18nTranslate translationKey="opportunity.import.drag-and-drop" />
						<label htmlFor="file-upload">
							{emptyStateStyle ? (
								<div className={styles.emptyState__import__btn}>
									{translate("opportunity.import.button")}
									<input
										ref={inputBrowserRef}
										hidden
										multiple
										accept=".zip,.pdf,.docx,.doc,.odt,.xlsx,.xlsm"
										id="file-upload"
										type="file"
										onChange={handleBrowserUpload}
									/>
								</div>
							) : (
								<a className={styles.import__btn} href={handleBrowserUpload}>
									<input
										ref={inputBrowserRef}
										hidden
										multiple
										accept=".zip,.pdf,.docx,.doc,.odt,.xlsx,.xlsm"
										id="file-upload"
										type="file"
										onChange={handleBrowserUpload}
									/>
									{translate("opportunity.import.button")}
								</a>
							)}
						</label>
					</div>
					<div>
						<I18nTranslate translationKey="opportunity.import.drag-and-drop-max-text" />
					</div>
					<div>
						<I18nTranslate translationKey="opportunity.import.disclaimer" />
					</div>
				</div>
			</div>
			<OpportunityProgress
				pendingProjects={pendingProjects}
				setPendingProjects={setPendingProjects}
				onCloseAll={handleOpenConfirmationAll}
				onCloseOne={handleOpenConfirmationOne}
				onCloseUploadDialog={handleOpenConfirmationAll}
			/>
			<CustomDialog
				closeLabel={translate("opportunity.upload.cancel.confirm-dialog.confirmation-continue-btn")}
				open={openCancelDialogOne}
				submitLabel={translate("opportunity.upload.cancel.confirm-dialog.confirmation-cancel-btn")}
				subTitle={translate("opportunity.upload.cancel.confirm-dialog.confirmation-text")}
				title={translate("opportunity.upload.cancel.confirm-dialog.confirmation-title")}
				onClose={handleCloseConfirmationOne}
				onSubmit={handleConfirmCancelOne}
			/>
			<CustomDialog
				closeLabel={translate("opportunity.upload.cancel.confirm-dialog.confirmation-continue-btn")}
				open={openCancelDialogAll}
				submitLabel={translate("opportunity.upload.cancel.confirm-dialog.confirmation-cancel-btn")}
				subTitle={translate("opportunity.upload.cancel.confirm-dialog.confirmation-text", { name: cancelItem })}
				title={translate("opportunity.upload.cancel.confirm-dialog.confirmation-title")}
				onClose={handleCloseConfirmationAll}
				onSubmit={handleConfirmCancelAll}
			/>
			<CustomDialog
				checkboxLabel={
					<div className={styles.checkboxLabel}>
						<div className={styles.checkboxLabel__title}>
							{translate("opportunity.multi-detected.dialog.checkbox-option.title")}
						</div>
						<div className={styles.checkboxLabel__subtitle}>
							{translate("opportunity.multi-detected.dialog.checkbox-option.description")}
						</div>
					</div>
				}
				checked={!isSingleProject}
				closeLabel={translate("common:btn.cancel")}
				open={openMulti}
				submitLabel={translate("common:btn.validate")}
				subTitle={translate("opportunity.multi-detected.dialog.description")}
				title={translate("opportunity.multi-detected.dialog.title")}
				onChangeCheckbox={() => setIsSingleProject((prev) => !prev)}
				onClose={hanldeCancelImport}
				onSubmit={handleSubmitImport}
			/>
			<OpportunityCreationPanel
				initial={toSendDocuments}
				isSingleProject={isSingleProject}
				open={openSidePanel}
				onClose={handleClosePanel}
				onSubmit={uploadProject}
			/>
		</div>
	);
}
