import React, { useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	CircularLoaderWithBackground,
	CustomDialog,
	CustomIconButton,
	GenericDialog,
	icon,
} from "../../../../common/components";
import { translate } from "../../../../common/providers";
import { resetImports, setOpenUploadDialog } from "../../slice/document-center-slice";
import UploadFile from "./upload-file/UploadFile";
import styles from "./UploadDialog.module.css";
import { isNonEmptyArray, isNumber, multiSort } from "../../../../common/utils";

export default function UploadDialog({ open }) {
	const dispatch = useDispatch();
	const imports = useSelector(({ documentCenter }) => documentCenter.imports);
	const importStats = useSelector(({ documentCenter }) => documentCenter.importStats);
	const analyzingDocuments = useSelector(({ documentCenter }) => documentCenter.analyzingDocuments);
	const hadDocumentsToAnalyze = useSelector(({ documentCenter }) => documentCenter.hadDocumentsToAnalyze);
	const [reduced, dispatchReduced] = useReducer((prev) => !prev, false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState({ open: false, onConfirm: null, documentName: "" });
	const handleCloseConfirmDialog = () => setOpenConfirmDialog({ open: false, onConfirm: null, documentName: "" });
	const handleCloseUploadDialog = () => {
		handleCloseConfirmDialog();
		dispatch(setOpenUploadDialog(false));
		dispatch(resetImports());
	};
	const remainingTimeText = useMemo(() => {
		let totalRemainingTime = 0;
		for (const batchUpload of importStats) {
			const { progression, total, uploadSpeed } = batchUpload;
			if (isNumber(progression)) {
				const remainingData = (total * (100 - progression)) / 100;
				const remainingTimeSeconds = remainingData / uploadSpeed;
				totalRemainingTime += remainingTimeSeconds;
			}
		}
		const minutes = Math.floor(totalRemainingTime / 60);
		const seconds = Number.parseInt(totalRemainingTime % 60, 10);
		if (!minutes && !seconds && !analyzingDocuments) {
			return translate("document-center.upload-dialog.upload.countdown");
		}
		if (minutes === 0) {
			return translate("document-center.upload-dialog.upload.countdown.seconds", { seconds });
		}
		if (seconds === 0) {
			return translate("document-center.upload-dialog.upload.countdown.minutes", { minutes });
		}
		return translate("document-center.upload-dialog.upload.countdown.minutes-seconds", { seconds, minutes });
	}, [analyzingDocuments, importStats]);
	const uploadProgress = useMemo(() => {
		let totalUploadData = 0;
		let totalUploadedData = 0;
		for (const batchUpload of importStats) {
			const { progression, total } = batchUpload;
			if (progression >= 0) {
				totalUploadData += total;
				totalUploadedData += (progression / 100) * total;
			}
		}
		return (totalUploadedData / totalUploadData) * 100;
	}, [importStats]);
	const ongoingUploads = useMemo(
		() =>
			imports.reduce(
				(total, sublist) =>
					total +
					sublist.reduce(
						(subTotal, obj) => subTotal + (isNumber(obj.progression) && obj.progression !== 100 ? 1 : 0),
						0
					),
				0
			),

		[imports]
	);
	const sortedImports = useMemo(() => {
		if (isNonEmptyArray(imports)) {
			const flattenedImports = multiSort(
				imports.map((i, batchId) => [...i.map((ip) => ({ ...ip, batchId }))]).flat(1),
				["name"]
			);
			return [
				...flattenedImports.filter((fi) => isNumber(fi.progression) && fi.progression < 100),
				...flattenedImports.filter((fi) => fi.progression === null),
				...flattenedImports.filter((fi) => fi.progression === 100),
			];
		}
		return [];
	}, [imports]);
	return (
		<GenericDialog
			disableBackdropClick
			disableEnforceFocus
			disableEscapeKeyDown
			hideBackdrop
			classes={{ paper: styles.dialog__paper, container: styles.dialog__container }}
			className={styles.dialog}
			open={open}
		>
			<div className={styles.dialog__content}>
				{(reduced && (
					<div className={styles.content__reduced}>
						<span className={styles.reduced__text}>
							{(isNumber(uploadProgress) && uploadProgress !== 100 && (
								<>
									<CircularLoaderWithBackground
										size={24}
										thickness={7.2}
										value={uploadProgress || 0}
										variant="determinate"
									/>
									&nbsp;
									{(ongoingUploads > 1 &&
										translate("document-center.upload-dialog.title.plural", {
											count: ongoingUploads,
										})) ||
										translate("document-center.upload-dialog.title.singular")}
								</>
							)) ||
								translate("document-center.upload-dialog.title.finish")}
						</span>
						<span className={styles.import__btns}>
							<CustomIconButton
								disableHovering
								btnClassName={styles.import__btn}
								icon={icon.faChevronUp}
								iconColor="var(--color-white)"
								size="md"
								onClick={dispatchReduced}
							/>
							{!ongoingUploads && (
								<CustomIconButton
									disableHovering
									btnClassName={styles.import__btn}
									icon={icon.faTimes}
									iconColor="var(--color-white)"
									size="md"
									onClick={handleCloseUploadDialog}
								/>
							)}
						</span>
					</div>
				)) || (
					<>
						<div className={styles.content__import}>
							<span className={styles.import__text}>
								{(ongoingUploads > 1 &&
									translate("document-center.upload-dialog.title.plural", {
										count: ongoingUploads,
									})) ||
									(ongoingUploads === 1 &&
										translate("document-center.upload-dialog.title.singular")) ||
									translate("document-center.upload-dialog.title.finish")}
							</span>
							<span className={styles.import__btns}>
								<CustomIconButton
									disableHovering
									btnClassName={styles.import__btn}
									icon={icon.faChevronDown}
									iconColor="var(--color-white)"
									size="md"
									onClick={dispatchReduced}
								/>
								{!ongoingUploads && (
									<CustomIconButton
										disableHovering
										btnClassName={styles.import__btn}
										icon={icon.faTimes}
										iconColor="var(--color-white)"
										size="md"
										onClick={handleCloseUploadDialog}
									/>
								)}
							</span>
						</div>
						<div className={styles.content__files}>
							{imports.length > 0 && !analyzingDocuments && !hadDocumentsToAnalyze && (
								<div className={styles.files__loaders}>
									<span className={styles.loader__text}>{remainingTimeText}</span>
								</div>
							)}
							<div className={styles["content__files--list"]}>
								{sortedImports.map(({ batchId, name, type, progression }, index) => (
									<UploadFile
										// eslint-disable-next-line
										key={index}
										batchId={batchId}
										name={name}
										progression={progression}
										type={type}
									/>
								))}
							</div>
						</div>
					</>
				)}
			</div>
			<CustomDialog
				maxWidth="xs"
				open={openConfirmDialog.open}
				subTitle={translate("document-center.upload-dialog.confirm-delete.text")}
				title={translate("document-center.upload-dialog.confirm-delete.title", {
					filename: openConfirmDialog.documentName,
				})}
				onClose={handleCloseConfirmDialog}
				onSubmit={openConfirmDialog.onConfirm}
			/>
		</GenericDialog>
	);
}
