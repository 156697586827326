import { Checkbox, Chip } from "@mui/material";
import React, { useMemo } from "react";
import {
	CustomIconButton,
	CustomTableCell,
	CustomTableRow,
	icon,
	IconComponent,
	TextMidCut,
} from "../../../../common/components";
import { isSegFeatureEnabled, SegFlags, translate, translateDate } from "../../../../common/providers";
import { compareInsensitiveCaseString, isNumber, matchTypeIcon } from "../../../../common/utils";
import PastilleScore from "./PastilleScore";
import styles from "./DocumentCenterTable.module.css";
import DocumentTableRowStatus from "./DocumentTableRowStatus";
import { FILE_TYPES } from "../../../../common/constants/file-types";
import { DOCUMENT_STATUS } from "../../constants/constants";

const DocumentTableRow = ({ document, hasActionPermission, isChecked, onDelete, onSelect, onClickRow, onOpenSP }) => {
	const handleClickRow = () => {
		onClickRow(document);
	};
	const handleOpenSP = (e) => {
		e.stopPropagation();
		onOpenSP(document.id, document.type, document.status);
	};
	const handleSelect = (e) => {
		e.stopPropagation();
		onSelect(document.id, document.type);
	};
	const scoreColor = useMemo(() => {
		if (!isNumber(document?.parsing_score)) {
			return null;
		}
		if (document?.parsing_score < 30) {
			return "red";
		}
		if (document?.parsing_score >= 85) {
			return "green";
		}
		return "orange";
	}, [document]);

	const documentRequirements = useMemo(() => {
		if (!isNumber(document?.reqs)) {
			return null;
		}
		if (document?.reqs > 0 && document?.type !== "folder") {
			return document.reqs;
		}
		if (document?.reqs <= 0 && document?.type !== "folder") {
			return "-";
		}
		return null;
	}, [document]);

	const displayStatusText = () => {
		switch (document?.status) {
			case DOCUMENT_STATUS.IN_PROGRESS:
				return <span>{translate("document-center.table.row.status.in-progress")}</span>;
			case DOCUMENT_STATUS.TIMEOUT:
			case DOCUMENT_STATUS.FAILED:
				return <span>{translate(`document-center.table.row.status.${document.status.toLowerCase()}`)}</span>;
			case DOCUMENT_STATUS.PENDING:
				return <span>{translate(`document-center.table.row.status.${document.status.toLowerCase()}`)}</span>;
			default:
				return null;
		}
	};
	return (
		<CustomTableRow
			key={document.id}
			hover
			onClick={(document?.status !== DOCUMENT_STATUS.IN_PROGRESS && handleClickRow) || null}
		>
			{({ isHovering }) => (
				<>
					{hasActionPermission && (
						<CustomTableCell>
							<Checkbox checked={isChecked} data-testid={`doc_${document.id}`} onClick={handleSelect} />
						</CustomTableCell>
					)}
					<CustomTableCell className={styles["table__cell--50"]}>
						<div className={styles.table__label}>
							<div
								className={`${styles["table__label--leftSide"]} ${
									(document.type === "folder" || document.docType !== "DELIVERABLE") &&
									styles["table__label--fullSize"]
								}`}
							>
								<div>
									<IconComponent
										className={styles.label__icon}
										data-type={document.type}
										icon={icon[matchTypeIcon({ type: document.type.toLowerCase() })]}
										size="md"
									/>
								</div>
								<span className={styles.label__textContainer}>
									<TextMidCut
										tooltip
										autoTranslate="no"
										className={styles.label__text}
										data-testid="doc.label"
										text={document.label}
										tooltipTitle={
											<div>
												<b>{document.label}</b>
												{document.label !== document.filename && <div>{document.filename}</div>}
											</div>
										}
									/>
									{document.label !== document.filename && (
										<TextMidCut
											autoTranslate="no"
											className={styles.label__subText}
											text={document.filename}
										/>
									)}
								</span>
							</div>
							{isSegFeatureEnabled(SegFlags.DOC_TYPE_DELIVERABLE) &&
								document.docType === "DELIVERABLE" && (
									<Chip
										className={styles.label__chip}
										label={translate("document-center.table.actions.type.deliverable")}
										size="small"
									/>
								)}
						</div>
					</CustomTableCell>
					<CustomTableCell className={styles["table__cell--10"]}>{document.version}</CustomTableCell>
					<CustomTableCell className={styles["table__cell--10"]}>
						{translateDate(document.updated)}
					</CustomTableCell>
					<CustomTableCell className={styles["table__cell--10"]}>
						{document.pages > 0 && document.type !== "folder" && document.pages}
					</CustomTableCell>
					<CustomTableCell className={styles["table__cell--10"]}>{documentRequirements}</CustomTableCell>
					<CustomTableCell className={`${styles.qualityColumn} ${styles["table__cell--10"]}`}>
						{!compareInsensitiveCaseString(document.type, FILE_TYPES.FOLDER) &&
							!compareInsensitiveCaseString(document.type, FILE_TYPES.UNSUPPORTED) &&
							((scoreColor && (
								<div>
									<PastilleScore color={scoreColor} />
								</div>
							)) || <div className={styles.status}>{displayStatusText()}</div>)}
					</CustomTableCell>
					<CustomTableCell className={styles["table__cell--32px"]}>
						{isHovering &&
							![DOCUMENT_STATUS.IN_PROGRESS, DOCUMENT_STATUS.FAILED, DOCUMENT_STATUS.TIMEOUT].includes(
								document.status
							) && <CustomIconButton icon={icon.faEllipsisV} size="sm" onClick={handleOpenSP} />}
						{[DOCUMENT_STATUS.IN_PROGRESS, DOCUMENT_STATUS.FAILED, DOCUMENT_STATUS.TIMEOUT].includes(
							document.status
						) && (
							<DocumentTableRowStatus
								docId={document.id}
								docStatus={document?.status}
								progression={document?.progression}
								onDelete={onDelete}
							/>
						)}
					</CustomTableCell>
				</>
			)}
		</CustomTableRow>
	);
};

export default DocumentTableRow;
