import React, { useState, useRef, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { InfiniteLoader, AutoSizer, List, CellMeasurerCache } from "react-virtualized";
import {
	translate,
	hasPermission,
	hasCompanyPermission,
	Permissions,
	isFeatureEnabled,
	Flags,
	translateEnumParam,
	translateEnumContentType,
	translateEnumInformationAttribute,
} from "../../../../common/providers";
import { ActionDialog } from "../dialogs";
import styles from "./SmartSearchResult.module.css";
import { CircularLoader, EmptyState, generateFilters, I18nTranslate, Preview } from "../../../../common/components";
import { ApiService, SearchCardActionService } from "../../../../api";
import {
	capitalize,
	isNonEmptyArray,
	nonNullAndDifferent,
	nonNullAndDifferentObjects,
	removeUnderscore,
	strictValueBetween,
} from "../../../../common/utils";
import { usePrevious } from "../../../../common/hooks";
import { CONTENT_TYPE, CLASSIFICATION, CATEGORIES, CRITICALITY, NEGOTIABILITY, TYPE } from "../../utils/utils";
import ResultRow from "./result-row/ResultRow";
import ActionsRow from "./actions-row/ActionsRow";
import { SEARCH_TYPES } from "../../../../common/constants/search-types";
import { initThematicTree } from "../../../../common/utils/category-utils";
import Sort from "../../../../common/components/drop-down/sort-drop-down/Sort";
import { setSortAlgorithm } from "../../my-search/slice/my-search-slice";

const cache = new CellMeasurerCache({
	fixedWidth: true,
	defaultHeight: 150,
	minHeight: 50,
});
const generatePayload = (checkAll, filters, isFilterTocOut, checkedRows, idsToExclude) => {
	if (checkAll && idsToExclude.length > 0) {
		const payloadFilters = JSON.parse(JSON.stringify(filters));
		if (payloadFilters.informationIds) {
			if (payloadFilters.informationIds.is) {
				payloadFilters.informationIds.elements = (payloadFilters.informationIds.elements || []).filter(
					(id) => !idsToExclude.includes(id)
				);
			} else {
				payloadFilters.informationIds.elements = (payloadFilters.informationIds.elements || []).concat(
					idsToExclude
				);
			}
		} else {
			payloadFilters.informationIds = { elements: [...idsToExclude], is: false };
		}
		return { filters: generateFilters({ ...payloadFilters, isFilterTocOut, Type: SEARCH_TYPES.card }) };
	}
	return checkAll
		? { filters: { ...generateFilters(filters), isFilterTocOut, Type: SEARCH_TYPES.card } }
		: { ids: checkedRows.map((row) => row.informationId) };
};

function countAddRemove(arr, acc = { deleteThematic: [], addThematic: [] }) {
	for (const o of arr) {
		if (o.contentsCount > 0 && !o.checked && o.visited) {
			acc.deleteThematic.push(o.id);
		}
		if (o.checked && o.visited) {
			acc.addThematic.push(o.id);
		}
		if (Array.isArray(o.subThematic) && o.subThematic.length > 0) {
			acc = countAddRemove(o.subThematic, acc); // eslint-disable-line no-param-reassign
		}
	}
	return acc;
}

function hasPermissions({ projectId, flags, permissions, companyPermissions }) {
	return (
		projectId && isFeatureEnabled(flags) && hasPermission(permissions) && hasCompanyPermission(companyPermissions)
	);
}

export default function SmartSearchResult({ disableActions = false, onReset, onSearch, slice }) {
	const [isLoadingItems, setIsLoadingItems] = useState(false);
	const [isProcessingAction, setIsProcessingAction] = useState(false);
	const [checkAll, setCheckAll] = useState(false);
	const [resultCount, setResultCount] = useState(0);
	const [totalSelectedReq, setTotalSelectedReq] = useState(0);
	const [openDropdown, setOpenDropdown] = useState("");
	const [preview, setPreview] = useState(null);
	const [dialogType, setDialogType] = useState(null);
	const [items, setItems] = useState([]);
	const [checkedRows, setCheckedRows] = useState([]);
	const [informationAttributes, setInformationAttributes] = useState([]);
	const [idsToExclude, setIdsToExclude] = useState([]);
	const [shortcutOn, setShortcutOn] = useState(false);
	const [lastChecked, setLastChecked] = useState(null);
	const hasFilters = useSelector((state) => state[slice].hasFilters);
	const results = useSelector((state) => state[slice].results);
	const hasMore = useSelector((state) => state[slice].hasMore);
	const totalResults = useSelector((state) => state[slice].totalResults);
	const totalMatches = useSelector((state) => state[slice].totalMatches);
	const isLoading = useSelector((state) => state[slice].isLoadingSearch);
	const pageNumber = useSelector((state) => state[slice].page);
	const totalPages = useSelector((state) => state[slice].totalPages);
	const project = useSelector(({ context }) => context.project);
	const limit = useSelector((state) => state[slice].limit);
	const filters = useSelector((state) => state[slice].filters);
	const isFilterTocOut = useSelector((state) => state[slice].isFilterTocOut);
	const selectedSortAlgo = useSelector((state) => state[slice].selectedSortAlgo);
	const sortingAlgorithms = useSelector((state) => state[slice].sortingAlgorithms);
	const pFilters = usePrevious(filters);
	const pSortAlgo = usePrevious(selectedSortAlgo);
	const cancelTokenSourceRef = useRef(null);
	const listRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	const refreshRefCache = (rowIndex) => {
		cache.clearAll();
		listRef.current?.recomputeRowHeights(rowIndex ?? 0);
		listRef?.current?.forceUpdate();
		listRef?.current?.forceUpdateGrid();
	};
	const handleReset = () => {
		setCheckAll(false);
		setCheckedRows([]);
		setResultCount(0);
		setTotalSelectedReq(0);
		setOpenDropdown(false);
		setDialogType(null);
		setItems([]);
		setIdsToExclude([]);
	};
	const handleError = (err) => {
		console.error(err);
		setIsLoadingItems(false);
		setIsProcessingAction(false);
	};
	useEffect(() => {
		if (nonNullAndDifferentObjects(pFilters, filters)) {
			refreshRefCache();
			handleReset();
		}
	}, [pFilters, filters]);
	useEffect(() => {
		if (nonNullAndDifferent(pSortAlgo, selectedSortAlgo)) {
			refreshRefCache();
			handleReset();
		}
	}, [selectedSortAlgo, pSortAlgo]);

	const handleRefreshHeight = (rowIndex) => {
		refreshRefCache(rowIndex);
		setTimeout(() => listRef?.current?.scrollToRow(rowIndex), 0);
	};
	useEffect(() => {
		const cancelToken = ApiService.getCancelTokenSource();
		if (
			hasPermissions({
				flags: Flags.SEARCHCARD_ACTIONS,
				projectId: project.id,
				permissions: [Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER],
				companyPermissions: Permissions.PROJECT_LEADER,
			})
		) {
			SearchCardActionService.getProjectInformationAttributes({ projectId: project.id }, cancelToken.token)
				.then((data) => Array.isArray(data) && setInformationAttributes(data))
				.catch(handleError);
		}
		return () => {
			ApiService.cancelTokens(cancelToken);
		};
	}, [project]);
	useEffect(() => {
		setResultCount(results.length);
	}, [results]);
	useEffect(() => {
		if (checkAll) {
			setCheckedRows((prev) => [
				...prev,
				...results.filter((r, index) => index >= resultCount).map((row) => ({ ...row, checked: true })),
			]);
			setResultCount(results.length);
		}
	}, [checkAll, results, resultCount]);

	useEffect(() => {
		const handleKeydown = (e) => {
			if (e.shiftKey && !shortcutOn) {
				setShortcutOn(true);
			}
		};
		const handleKeyUp = (e) => {
			if (!e.shiftKey && shortcutOn) {
				setShortcutOn(false);
			}
		};
		document.addEventListener("keydown", handleKeydown);
		document.addEventListener("keyup", handleKeyUp);
		return () => {
			document.removeEventListener("keydown", handleKeydown);
			document.removeEventListener("keyup", handleKeyUp);
		};
	}, [shortcutOn]);

	const handleCheckRow = (checked, row) => {
		setLastChecked(row);
		const singleCheck = !shortcutOn || !lastChecked;
		if (singleCheck) {
			if (checked) {
				setCheckedRows((prev) => [...prev, row]);
				if (checkAll) {
					setIdsToExclude((prev) => prev.filter((id) => id !== row.informationId));
				}
			} else {
				setCheckedRows((prev) => prev.filter((r) => r.informationId !== row.informationId));
				if (checkAll) {
					setIdsToExclude((prev) => [...prev, row.informationId]);
				}
			}
		} else {
			const indexLastChecked = results.findIndex((x) => x.informationId === lastChecked.informationId);
			const currentChecked = results.findIndex((x) => x.informationId === row.informationId);
			const sortedIndexes = [indexLastChecked, currentChecked].sort((a, b) => a - b);
			const range = results.slice(sortedIndexes[0], sortedIndexes[1] + 1);
			if (checked) {
				setCheckedRows((prev) => [
					...prev,
					...range.filter((x) => !checkedRows.find((y) => y.informationId === x.informationId)),
				]);
				if (checkAll) {
					setIdsToExclude((prev) => prev.filter((id) => !range.some((x) => x.informationId === id)));
				}
			} else {
				setCheckedRows((prev) => prev.filter((x) => !range.find((y) => y.informationId === x.informationId)));
				if (checkAll) {
					setIdsToExclude((prev) => [
						...prev,
						...range.filter((y) => !prev.some((x) => x === y.informationId)).map((x) => x.informationId),
					]);
				}
			}
		}
	};
	const isRowLoaded = ({ index }) => !hasMore || index < results.length;
	const handleLoadMore = () => {
		if (pageNumber < totalPages - 1) {
			return onSearch({
				page: pageNumber + 1,
				limit,
				filters: generateFilters(filters),
				sort: selectedSortAlgo,
				token: cancelTokenSourceRef.current.token,
			});
		}
		return null;
	};
	const handleChangeSort = (algo) => {
		dispatch(setSortAlgorithm(algo));
		onSearch({
			page: 0,
			limit,
			filters: generateFilters(filters),
			sort: algo,
			token: cancelTokenSourceRef.current.token,
		});
	};
	const handleCloseDisplay = () => setPreview(null);
	const handleRefreshOnUpdate = () => {
		onReset();
		setDialogType(null);
		setIsProcessingAction(false);
		setCheckedRows([]);
		setIdsToExclude([]);
		setCheckAll(false);
		handleRefreshHeight();
	};
	const fetchCategoryCounters = () => {
		setIsLoadingItems(true);
		SearchCardActionService.getCategoryCounters(
			{ projectId: project.id },
			generatePayload(checkAll, filters, isFilterTocOut, checkedRows, idsToExclude),
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				if (Array.isArray(data)) {
					const total = checkAll ? totalMatches - idsToExclude.length : checkedRows.length;
					setItems(initThematicTree(data, total));
				}
				setIsLoadingItems(false);
			})
			.catch(handleError);
	};
	const fetchInformationAttributesCounters = (attribute) => {
		setIsLoadingItems(true);
		SearchCardActionService.getInformationAttributesCounters(
			{ projectId: project.id, attribute },
			generatePayload(checkAll, filters, isFilterTocOut, checkedRows, idsToExclude),
			cancelTokenSourceRef.current.token
		)
			.then((data) => {
				if (Array.isArray(data)) {
					setItems(data.map((x) => ({ ...x, displayName: translateEnumParam(attribute, x.name) })));
				}
				setIsLoadingItems(false);
			})
			.catch(handleError);
	};
	const fetchTypeCounters = (callback) => {
		setIsLoadingItems(true);
		SearchCardActionService.getTypeCounters(
			{ projectId: project.id },
			generatePayload(checkAll, filters, isFilterTocOut, checkedRows, idsToExclude),
			cancelTokenSourceRef.current.token
		)
			.then(callback)
			.catch(handleError);
	};
	const updateInformationAttributes = (value, key, payload) => {
		if (key) {
			switch (key.toUpperCase()) {
				case CRITICALITY:
					payload.reqCriticality = value;
					break;
				case NEGOTIABILITY:
					payload.reqNegotiability = value;
					break;
				case TYPE:
					payload.reqType = value;
					break;
				default:
					payload[key] = value;
			}
			SearchCardActionService.updateProjectInformationAttributes(
				{ projectId: project.id },
				payload,
				cancelTokenSourceRef.current.token
			)
				.then(() => {
					handleRefreshOnUpdate();
				})
				.catch(handleError);
		}
	};
	const updateCategories = (rows, analyticalAxe, payload) => {
		const requestPayload = {
			...payload,
			...countAddRemove(rows),
		};
		SearchCardActionService.updateCategories(
			{ projectId: project.id },
			requestPayload,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				handleRefreshOnUpdate();
			})
			.catch(handleError);
	};
	const updateType = (type, payload) => {
		const requestPayload = { type, ...payload };
		SearchCardActionService.updateType(
			{ projectId: project.id },
			requestPayload,
			cancelTokenSourceRef.current.token
		)
			.then(() => {
				handleRefreshOnUpdate();
			})
			.catch(handleError);
	};
	const handleCheckAllRows = (e) => {
		const { checked } = e.target;
		setCheckAll(checked);
		setLastChecked(null);
		if (checked) {
			setIdsToExclude([]);
		}
		setCheckedRows(checked ? results : []);
	};
	const handleToggle = (dropdown) => {
		if (dropdown === CATEGORIES && dropdown !== openDropdown) {
			fetchCategoryCounters();
		}
		setOpenDropdown(dropdown && dropdown === openDropdown ? "" : dropdown);
	};
	const handleCloseDropdown = () => handleToggle("");
	const handleDataTypeCounters = (data) => {
		if (isNonEmptyArray(data)) {
			setItems(
				data.map((type) => ({
					displayName: translateEnumContentType(type.contentType),
					name: type.contentType,
					count: type.count,
				}))
			);
			setIsLoadingItems(false);
		}
	};
	const handleContentTypeOnClassification = (data) => {
		if (isNonEmptyArray(data)) {
			const dataType = data.find((ct) => ct?.contentType?.toLowerCase() === "requirement");
			setTotalSelectedReq(dataType?.count || 0);
			setIsLoadingItems(false);
		}
	};
	const handleOpenDialog = ({ item, type }) => {
		handleToggle(openDropdown);
		switch (type.toUpperCase()) {
			case CATEGORIES: {
				setDialogType({
					name: translate("common:header.filter.project-categories"),
					type: CATEGORIES,
					analyticalAxe: translate("common:header.filter.project-categories"),
				});
				break;
			}
			case CLASSIFICATION:
				fetchInformationAttributesCounters(item.name?.toLowerCase());
				fetchTypeCounters(handleContentTypeOnClassification);
				setDialogType({
					displayName: translateEnumInformationAttribute(item.name?.toLowerCase()),
					name: item.name?.toLowerCase(),
					type: CLASSIFICATION,
				});
				break;
			case CONTENT_TYPE:
				fetchTypeCounters(handleDataTypeCounters);
				setDialogType({
					name: translate(`smart-search.results.${CONTENT_TYPE.replaceAll("_", "-")?.toLowerCase()}`),
					type: CONTENT_TYPE,
				});
				break;
			default:
		}
	};
	const handleDisplay = (informationId, documentId, page, informationType, coordinates) =>
		setPreview({
			coordinates,
			documentId,
			page,
			informationId,
			informationType,
		});

	const rowRenderer = ({ key, index, parent, style }) => {
		const result = results[index];
		return (
			<ResultRow
				key={key}
				cache={cache}
				checkedRows={checkedRows}
				disableActions={disableActions}
				index={index}
				parent={parent}
				result={result}
				style={style}
				onCheckRow={handleCheckRow}
				onDisplay={handleDisplay}
				onRefreshHeight={handleRefreshHeight}
			/>
		);
	};
	const handleSubmit = (rows, dType) => {
		setIsProcessingAction(true);
		const payload = generatePayload(checkAll, filters, isFilterTocOut, checkedRows, idsToExclude);
		switch (dType.type) {
			case CONTENT_TYPE:
				updateType(rows.find((r) => r.checked).name.toUpperCase(), payload);
				break;
			case CLASSIFICATION:
				{
					const row = rows.find((r) => r.checked);
					if (row) {
						updateInformationAttributes(row.name, dType.name, payload);
					}
				}
				break;
			case CATEGORIES:
				updateCategories(rows, dType.analyticalAxe, payload);
				break;
			default:
		}
	};
	const selectedCount = checkAll ? totalMatches - idsToExclude.length : checkedRows.length;
	const handlePlural = (elements, string) => {
		const translateType = "common:results.count-";
		if (elements > 1) {
			return translateType.concat(string);
		}
		return translateType.concat("single-", string);
	};
	return isLoading ? (
		<div className={`${styles.result} ${styles.result__loader}`}>
			<CircularLoader color="primary" size={80} />
		</div>
	) : (
		<>
			<div className={styles.result}>
				{(hasFilters && (
					<>
						<div className={styles.result__results}>
							{isFeatureEnabled(Flags.SEARCHCARD_ACTIONS) &&
								hasPermission(Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER) &&
								hasCompanyPermission(Permissions.PROJECT_LEADER) && (
									<Checkbox
										checked={checkedRows.length > 0}
										color="primary"
										data-testid="add.content.checkbox"
										indeterminate={strictValueBetween(0, checkedRows.length, results.length)}
										size="medium"
										onChange={handleCheckAllRows}
									/>
								)}
							<span className={styles.results__count} data-testid="search.result.count">
								{hasFilters &&
									((checkedRows.length === 0 && (
										<I18nTranslate
											param={{ totalMatches, totalResults }}
											translationKey={handlePlural(totalMatches, "match")}
										/>
									)) || (
										<I18nTranslate
											param={{
												totalMatches,
												nbSelected: selectedCount,
											}}
											translationKey={handlePlural(selectedCount, "selected-match")}
										/>
									))}
							</span>
							{checkedRows.length > 0 && (
								<ActionsRow
									informationAttributes={informationAttributes}
									isLoadingItems={isLoadingItems}
									openDropdown={openDropdown}
									onCloseDropdown={handleCloseDropdown}
									onOpenDialog={handleOpenDialog}
									onToggle={handleToggle}
								/>
							)}
							<span className={styles.results__spacer} />
							<Sort
								selectedSortAlgo={selectedSortAlgo}
								sortingAlgorithms={sortingAlgorithms}
								onChangeSort={handleChangeSort}
							/>
						</div>
						{hasFilters && totalMatches === 0 && (
							<span className={styles.results__info}>
								<EmptyState
									className={`${styles.emptyStateWrapper}`}
									title={translate("smart-search.results.row.filter-search-no-match")}
								/>
							</span>
						)}
						<div className={styles.results__listWrapper}>
							<InfiniteLoader
								isRowLoaded={isRowLoaded}
								loadMoreRows={handleLoadMore}
								rowCount={(hasFilters && totalMatches) || totalResults}
								threshold={10}
							>
								{({ onRowsRendered, registerChild }) => (
									<AutoSizer>
										{({ width, height }) => (
											<List
												ref={(ref) => {
													listRef.current = ref;
													registerChild(ref);
												}}
												deferredMeasurementCache={cache}
												height={height}
												rowCount={results.length}
												rowHeight={cache.rowHeight}
												rowRenderer={rowRenderer}
												width={width}
												onRowsRendered={onRowsRendered}
											/>
										)}
									</AutoSizer>
								)}
							</InfiniteLoader>
						</div>
					</>
				)) || <div className={styles.startFiltering}>{translate("smart-search.no-filters")}</div>}
			</div>
			<Preview
				coordinates={preview?.coordinates}
				docId={preview?.documentId}
				infoId={preview?.informationId}
				infoType={preview?.informationType}
				open={!!preview}
				page={preview?.page}
				projectId={project?.id}
				onClose={handleCloseDisplay}
			/>
			{dialogType && (
				<ActionDialog
					isLoading={isLoadingItems}
					isProcessing={isProcessingAction}
					items={items}
					multiple={dialogType?.type === CATEGORIES}
					none={dialogType?.type === CLASSIFICATION}
					open={!!dialogType}
					title={capitalize(removeUnderscore(dialogType.displayName || dialogType.name))}
					totalCount={selectedCount}
					totalSelectedReq={dialogType?.type === CLASSIFICATION && totalSelectedReq}
					type={dialogType}
					onClose={() => {
						setDialogType(null);
					}}
					onSubmit={handleSubmit}
				/>
			)}
		</>
	);
}
