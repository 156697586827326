import { Checkbox } from "@mui/material";
import React from "react";
import { CategoryRowRiskLevel, CustomIconButton, Hierarchy, icon, IconComponent, VerticalDivider } from "../..";
import { isSegFeatureEnabled, SegFlags, translate } from "../../../providers";
import { dangerousSetInnerHTML } from "../../../utils";
import styles from "./SearchRow.module.css";
import { SENTENCE_TYPE } from "../../../../views/my-review/constants/constants";

const DISPLAY_SCORE = false;
export default function SearchRow({
	categories,
	content,
	documentId,
	documentName,
	informationId,
	isCheckboxDisplayed,
	isChecked,
	isTemplate,
	location,
	onCheck,
	onDisplay,
	page,
	row,
	score,
	informationType,
}) {
	const handleClickDisplay = () => {
		onDisplay(informationId, documentId, page, informationType, row.rectangleDTO);
	};
	return (
		<div className={styles.row}>
			<div className={styles.row__information}>
				<div className={styles["row__information--leftSide"]}>
					{isCheckboxDisplayed && (
						<Checkbox
							checked={isChecked}
							color="primary"
							data-testid={`check_information_row_${informationId}`}
							size="medium"
							onChange={(e) => {
								onCheck(e.target.checked, row);
							}}
						/>
					)}
					<span className={styles.row__title}>
						{informationType === SENTENCE_TYPE.INFORMATION ||
						(!isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS) && !isTemplate) ? (
							<>
								<IconComponent
									className={styles.infoIcon}
									color="var(--color-light-grey-1)"
									icon={icon.faStream}
								/>
								{`INFO_${informationId}`}
							</>
						) : (
							<>
								<IconComponent
									className={styles.requiredIcon}
									color="var(--color-red)"
									icon={icon.faAsterisk}
								/>
								{`REQ_${informationId}`}
							</>
						)}
					</span>
					<VerticalDivider className={styles.divider} />
					<Hierarchy
						enableFirstLocationCut
						isDangerousSetInnerHTML
						className={styles.breadcrumbContainer}
						documentName={documentName}
						hierarchy={location}
					/>
				</div>
				<div className={styles["row__information--actions"]}>
					{DISPLAY_SCORE && (
						<span className={styles["row__information--score"]}>
							{(score === 0 || score) && `${score}%`}
						</span>
					)}
					<CustomIconButton
						data-testid={`file.icon.btn_${informationId}`}
						icon={icon.faFile}
						size="sm"
						tooltip={translate("common:btn.display")}
						variant="outlined"
						onClick={handleClickDisplay}
					/>
				</div>
			</div>
			{Array.isArray(categories) && categories.length > 0 && (
				<div className={styles.row__attributes}>
					<CategoryRowRiskLevel categories={categories} containerId={informationId} />
				</div>
			)}
			<p className={styles.row__description}>
				{content ? (
					dangerousSetInnerHTML({ text: content })
				) : (
					<>{translate("common:searchcard.image-matched")}</>
				)}
			</p>
		</div>
	);
}
