import React from "react";
import { icon, IconComponent, CustomTooltip } from "../../../../common/components";
import styles from "./Directory.module.css";

export default function Directory({ disabled = false, children, currentDirectoryId, node, onClickDirectory }) {
	const isCurrent = node.id === currentDirectoryId;
	const handleClickDirectory = () => {
		if (!isCurrent) {
			onClickDirectory(node);
		}
	};
	return (
		<span
			className={styles.directory}
			disabled={disabled}
			role="button"
			tabIndex={0}
			type="button"
			onClick={handleClickDirectory}
			onKeyDown={null}
		>
			<IconComponent
				color={
					(disabled && "var(--color-light-grey-1") ||
					(isCurrent && "var(--color-blue)") ||
					"var(--color-light-grey-2)"
				}
				icon={icon.faFolder}
				size="lg"
			/>
			<CustomTooltip arrow showWhenOverflow placement="right" title={children}>
				<span
					className={`${styles.directory__label} ${(isCurrent && styles["directory__label--current"]) || ""}`}
					data-testid="directory.tree.name"
					disabled={disabled}
				>
					{children}
				</span>
			</CustomTooltip>
		</span>
	);
}
