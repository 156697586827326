export const KEY_LANG = "lang";
export const KEY_SMART_VIEW = "smart_view";
export const KEY_DASHBOARD = "dashboard";
export const NO_ROLE_ON_DOCUMENT_BANNER_DISABLED = "no_role_on_document_banner_disabled";
export const KEY_USER_EMAIL = "email";
export const KEY_USER_DISPLAY_NAME = "displayName";
export const KEY_USER_ID = "userId";
export const KEY_USER_COMPANY = "companyId";
export const HOME_TAB = "homeTab";
export const KEY_SMART_VIEW_FILTER = "smart_view_filter";
export const HIDE_DOC_STATUS_INFO_BANNER = "hide_doc_status_info_banner";
export const LOGGED_REAL_COMPANY = "logged_real_company";
