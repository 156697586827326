import React, { cloneElement, useEffect, useMemo, useState } from "react";
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import styles from "./FilterContent.module.css";
import { FILTER_TYPE } from "../../filters/common-filters";
import { IconComponent } from "../../../icon-component";
import { FilterWrapper } from "../..";
import { useEffectOnce } from "../../../../hooks";
import { LabeledCheckbox } from "../../../checkboxes";
import { ToggleButtons } from "../../../toggles";
import { translate } from "../../../../providers";
import { isNonEmptyArray, isNonEmptyObject, isObject } from "../../../../utils";

const FilterContent = ({
	dynamicItems,
	falseLabel,
	filterKey,
	items,
	label,
	multiSelection = false,
	onApply,
	onChange,
	renderer,
	trueLabel,
	type,
	value,
	labelKey,
	valueKey,
	readOnly,
	disablePortal,
	hasIsSeparator = false,
	hasAndOrOperator = false,
	resizable = false,
	...props
}) => {
	const [checked, setChecked] = useState(value || []);
	const [search, setSearch] = useState("");
	const handleCheck = (key) => {
		if (readOnly) {
			return;
		}
		if (Array.isArray(checked) && !hasIsSeparator && !hasAndOrOperator) {
			const newChecked = checked.includes(key) ? checked.filter((item) => item !== key) : [...checked, key];
			setChecked(newChecked);
			onChange(isNonEmptyArray(newChecked) ? newChecked : undefined);
		} else if (isObject(checked) && (hasIsSeparator || hasAndOrOperator)) {
			const normalizedChecked = {
				is: checked.is,
				elements: isNonEmptyArray(checked.elements) ? checked.elements : [],
			};
			Object.keys(checked).forEach((k) => {
				if (!["elements", "is"].includes(k) && !Number.isNaN(Number(k))) {
					if (!normalizedChecked.elements.includes(checked[k])) {
						normalizedChecked.elements.push(checked[k]);
					}
				}
			});
			const elements = normalizedChecked.elements || [];
			const newElements = elements.includes(key) ? elements.filter((item) => item !== key) : [...elements, key];
			const newChecked = { ...normalizedChecked, elements: newElements };
			setChecked(newChecked);
			onChange(newElements.length > 0 ? newChecked : undefined);
		}
	};
	const isChecked = (checkedItem, item, key) => {
		const itemValue = key ? item[key] : item;
		if (Array.isArray(checkedItem)) {
			return checkedItem.includes(itemValue);
		}
		if (isObject(checkedItem)) {
			if (Array.isArray(checkedItem.elements)) {
				return checkedItem.elements.includes(itemValue);
			}
		}
		return false;
	};
	useEffectOnce(
		() => {
			setChecked(value || []);
		},
		[value],
		() => value?.length > 0
	);
	useEffect(() => {
		if (value === undefined) {
			setChecked([]);
		} else {
			setChecked(value);
		}
	}, [value]);

	const currentItems = useMemo(() => dynamicItems || items, [dynamicItems, items]);
	const handleSearch = (searchValue) => {
		setSearch(searchValue);
	};
	const handleChange = () => {
		onChange(!value, true);
	};
	const handleChangeTextValue = (e) => {
		onChange(e.target.value);
	};
	return (
		<>
			{(type !== FILTER_TYPE.BOOLEAN && (
				<FilterWrapper
					disablePortal={disablePortal}
					filterKey={filterKey}
					hasAndOrOperator={hasAndOrOperator}
					hasIsSeparator={hasIsSeparator}
					label={label}
					onApply={onApply}
					onChange={onChange}
					{...props}
					readOnly={readOnly}
					resizable={resizable}
					search={search}
					value={value}
					onSearch={handleSearch}
				>
					<div className={styles.list__content}>
						{type === FILTER_TYPE.SIMPLE_LIST &&
							multiSelection &&
							((isNonEmptyObject(currentItems) && Object.entries(currentItems)) || []).map(
								([key, item]) => (
									<div
										key={key}
										className={styles.list_itemContainer}
										role="presentation"
										onClick={() => {
											handleCheck(valueKey ? item[valueKey] : item);
										}}
									>
										<div className={styles.list__rowHead}>
											<Checkbox
												checked={isChecked(checked, item, valueKey)}
												classes={{ root: styles.list__checkbox }}
												disabled={readOnly}
												size="small"
											/>
											<div className={styles.list__helperInformation}>
												{item?.iconName && item?.iconPlacement === "left" && (
													<IconComponent color={item.color} icon={item.iconName} />
												)}
												<span
													className={styles.list__rowLabel}
													data-icon-placement-left={item?.iconPlacement === "left"}
												>
													{labelKey ? translate(item[labelKey]) : item}
												</span>
												{item?.iconName &&
													(item?.iconPlacement === "right" || !item?.iconPlacement) && (
														<IconComponent color={item.color} icon={item.iconName} />
													)}
												{item?.description && (
													<div className={styles.list__rowDescription}>
														{translate(item.description)}
													</div>
												)}
											</div>
										</div>
									</div>
								)
							)}
						{type === FILTER_TYPE.SIMPLE_LIST && !multiSelection && (
							<RadioGroup
								className={styles.radioGroup__container}
								disabled={readOnly}
								value={value}
								onChange={(e) => onChange(e.target.value)}
							>
								{(Object.entries(currentItems) || []).map(([key, item]) => (
									<FormControlLabel
										key={key}
										className={styles.radioContainer}
										control={<Radio className={styles.radio} color="primary" />}
										disabled={readOnly}
										label={
											<div key={key}>
												<div className={styles.list__helperInformation}>
													<span className={styles.list__rowLabel}>
														{labelKey ? translate(item[labelKey]) : item}
													</span>
													{item?.iconName && (
														<IconComponent color={item.color} icon={item.iconName} />
													)}
													{item?.description && (
														<div className={styles.list__rowDescription}>
															{translate(item.description)}
														</div>
													)}
												</div>
											</div>
										}
										value={valueKey ? item[valueKey] : item}
									/>
								))}
							</RadioGroup>
						)}
						{type === FILTER_TYPE.TEXT_FIELD && (
							<TextField
								disabled={readOnly}
								error={value?.length === 0}
								helperText={translate("common:component.filters.input-filter.helper")}
								placeholder={translate(label)}
								value={value || ""}
								onChange={handleChangeTextValue}
							/>
						)}
						{!!renderer &&
							cloneElement(renderer, {
								filterKey,
								value,
								search,
								onChange,
								...props,
							})}
					</div>
				</FilterWrapper>
			)) ||
				(trueLabel && falseLabel && (
					<ToggleButtons
						exclusive
						content={[
							{
								value: false,
								label: translate(falseLabel),
							},
							{ value: true, label: translate(trueLabel) },
						]}
						value={value}
						onChange={handleChange}
					/>
				)) || (
					<LabeledCheckbox
						disabled={readOnly}
						label={translate(label)}
						labelPlacement="end"
						value={value}
						onChange={handleChange}
					/>
				)}
		</>
	);
};

export default FilterContent;
