import React from "react";
import { Collapse, Paper } from "@mui/material";
import styles from "./Section.module.css";
import { CustomIconButton, CustomTooltip, icon } from "../../../../../../../common/components";

export default function Section({
	children,
	className = "",
	collapsed = true,
	elevation = 1,
	sectionTag = "",
	title,
	onCollapse,
}) {
	const handleCollaspe = () => {
		if (typeof onCollapse === "function") {
			onCollapse(sectionTag);
		}
	};
	return (
		<section className={`${styles.section} ${className}`}>
			<CustomTooltip showWhenOverflow title={title}>
				<span className={styles.section__title}>
					<CustomIconButton
						icon={collapsed ? icon.faChevronDown : icon.faChevronRight}
						onClick={handleCollaspe}
					/>
					{title}
				</span>
			</CustomTooltip>
			<Collapse unmountOnExit in={collapsed} timeout="auto">
				<Paper square className={styles.section__content} elevation={elevation}>
					{children}
				</Paper>
			</Collapse>
		</section>
	);
}
